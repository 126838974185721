<!--
  PACKAGE_NAME : src/pages/cc/cti/ibg-category
  FILE_NAME : category-list.vue
  AUTHOR : hmlee
  DATE : 2024-07-09
  DESCRIPTION : 좌측 인입그룹 트리리스트 컴포넌트
-->
<template>
  <!-- 트리 리스트 -->
  <div class="layout-cut-left tree-box01 fl">
    <div class="treemenu-set">
      <DxTreeList
        id="treeList"
        ref="treeList"
        :data-source="tree.infoList"
        :expanded-row-keys="expandedRowKeys"
        :selected-row-keys="tree.selectedRowkeys"
        :focused-row-key="tree.focusedRowData.id"
        :root-value="-1"
        key-expr="id"
        parent-id-expr="parentId"
        :show-column-headers="true"
        :show-row-lines="true"
        :show-column-lines="false"
        :show-borders="true"
        :column-auto-width="true"
        @selection-changed="selectionChangedData"
        :on-row-click="onClickRow"
        :no-data-text="noDataText(tree.infoList.length)"
        :width="350"
        :height="tree.height"
      >
        <DxSearchPanel
          placeholder=""
          :visible="true"
          :searchVisibleColumnsOnly="true"
          :width="300"
        />

        <DxSelection
          :recursive="tree.recursive"
          :mode="tree.mode"
        />

        <DxRowDragging
          :on-drag-change="onDragChange"
          :on-reorder="onReorder"
          :allow-drop-inside-item="tree.allowDropInsideItem"
          :allow-reordering="tree.allowReordering"
          :show-drag-icons="tree.showDragIcons"
        />

        <DxScrolling mode="virtual"/>

        <DxColumn
          :caption="$_msgContents('CC.WORD.GROUP_NAME', {defaultValue: '그룹명'})"
          data-field="ctgNm"
          :calculate-cell-value="ctgNm"
        />

      </DxTreeList>
    </div>
  </div>

</template>

<script>
import {DxTreeList, DxColumn, DxScrolling, DxSelection, DxRowDragging, DxSearchPanel} from 'devextreme-vue/tree-list';

import {DxButton} from 'devextreme-vue/button';
import {isSuccess} from "@/plugins/common-lib";

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxScrolling,
    DxSelection,
    DxRowDragging,
    DxSearchPanel,
    DxButton,
  },
  props: {
    expandedRowKeys: {
      type: Array,
    },
    categoryLevel: {
      type: Number,
      default: 3,
    }
  },
  watch: {},
  data() {
    return {
      config: {
        stylingMode: 'outlined',    //[outlined, filled, underlined]
        isViewFl: true,
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      tree: {
        isClickTree: false,
        originInfoList: [],
        infoList: [],
        sortList: [],
        expandedRowKeys: [],
        selectedRowkeys: [],
        selectedRowsData: [],
        focusedRowData: null,
        recursive: false,       //재귀 선택 여부
        mode: 'multiple',
        selectionMode: 'excludeRecursive', //최상노드만 조회 / 'all' or 'excludeRecursive' or 'leavesOnly'
        allowDropInsideItem: false,
        allowReordering: true,
        showDragIcons: true,
      },
    }
  },
  computed: {},
  methods: {
    /** @description: 트리메뉴 변경 이벤트 */
    selectionChangedData(e) {
      const selectedData = e.component.getSelectedRowsData(this.tree.selectionMode);
      this.tree.selectedRowsData = selectedData;
      this.$emit('selectRow', selectedData)
    },
    /** @description : 트리 로우 데이터 클릭 이벤트 */
    onClickRow(e) {
      // 이전에 클릭된 행의 클래스를 제거
      const previouslyClicked = document.querySelector('.clicked-row');
      if (previouslyClicked) {
        previouslyClicked.classList.remove('clicked-row');
      }

      // 클릭된 행 요소에 클래스 추가
      e.rowElement.classList.add('clicked-row');

      const rowData = e.data;

      if (rowData) {
        this.tree.focusedRowData = rowData;
        this.$emit('clickRow', e);
      }
    },
    /** @description: 드래그 이벤트 */
    onDragChange(e) {
      this.tree.selectedRowkeys = [];
      let visibleRows = e.component.getVisibleRows(),
        sourceNode = e.component.getNodeByKey(e.itemData.id),
        targetNode = visibleRows[e.toIndex].node;
      while (targetNode && targetNode.data) {
        if (targetNode.data.id === sourceNode.data.id) {
          e.cancel = true;
          break;
        }
        targetNode = targetNode.parent;
      }
    },
    /** @description: 드래그 순서 변경 이벤트  */
    onReorder(e) {
      let visibleRows = e.component.getVisibleRows(),
        sourceData = e.itemData,
        targetData = visibleRows[e.toIndex].node.data,
        infoList = [...this.tree.infoList];
      if (e.dropInsideItem) {
        e.itemData.Head_ID = targetData.id;
        e.component.refresh();
      } else {
        let sourceIndex = infoList.indexOf(sourceData),
          targetIndex = infoList.indexOf(targetData);

        if (sourceData.Head_ID !== targetData.Head_ID) {
          sourceData.Head_ID = targetData.Head_ID;
          if (e.toIndex > e.fromIndex) {
            targetIndex++;
          }
        }

        infoList.splice(sourceIndex, 1);
        infoList.splice(targetIndex, 0, sourceData);
        this.tree.infoList = infoList;

        //순서 처리 및 세팅
        this.$emit('setOrder', {list: infoList, targetData: sourceData});
      }
    },
    /** @description : 트리 데이터 가져오는 메서드 */
    async selectDataList(sort = "+depth,+ibgCtgOrd") {
      const params = {sort};
      const payload = {
        actionname: 'CC_IBG_CATEGORY_LIST',
        data: params,
      }

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.tree.infoList = res.data.data;
        this.$emit('select', res.data.data);
      }
    },
    /** @description : 트리 데이터가 없을 때 출력 메서드 */
    noDataText(length) {
      if (length === 0) {
        return this.$_msgContents('COMMON.MESSAGE.CMN_NO_DATA', {defaultValue: '데이터가 없습니다.'});
      }
    },
    /** @description: 카테고리 이름 */
    ctgNm(rowData) {
      if (rowData.depth < this.categoryLevel) {
        return rowData.ctgNm;
      } else {
        return rowData.ibg.ibgNm;
      }
    },
    /** @description: 페이지 초기화 */
    initPage() {
      this.clearData(); //데이터 초기화
      this.selectDataList(); //트리리스트 데이터 조회
      this.refreshTreeList(); //트리리스트 refresh
      this.$parent.isOpenModal(false); //팝업 close
      this.$emit('initClickRow', this.tree.focusedRowData);   //데이터 초기화
    },
    /** @description: 데이터 초기화 */
    clearData() {
      this.tree.selectedRowkeys = [];
      this.tree.focusedRowData = {};
    },
    /** @description: treeList refresh 이벤트 */
    refreshTreeList() {
      if (this.$refs.treeList) {
        this.$refs.treeList.instance.refresh();
        this.$refs.treeList.instance.deselectAll(); //선택 해제
      }
    },
    /** @description: 높이 설정 */
    setHeight() {
      if (this.tree.height == null || this.tree.height == undefined) {
        let height = this.getTopElement('#treeList') + this.getHeightElement('.page-sub-box .dx-button');// + this.getHeightElement('.page-sub-box .top-toolbar-box') - 20;
        this.tree.height = 'calc(100vh - ' + height + 'px)';
      }
    },
    /** @description: 상단 위치 정보 */
    getTopElement(e) {
      const divElement = document.querySelector(e);
      const rect = divElement.getBoundingClientRect();
      return rect.top;
    },
    /** @description: element 높이 계산 */
    getHeightElement(e) {
      const divElement = document.querySelector(e);
      const computedStyle = window.getComputedStyle(divElement);
      const divHeight = divElement.offsetHeight;
      const marginTop = parseFloat(computedStyle.marginTop);
      const marginBottom = parseFloat(computedStyle.marginBottom);
      return divHeight + marginTop + marginBottom;
    },
  },
  created() {
    this.initPage();
  },
  mounted() {
    this.setHeight();
  }
}
</script>

<style lang="scss" scoped>
/** 트리리스트 검색 패널 관련 */
::v-deep  #treeList .dx-toolbar .dx-toolbar-after {
  margin: 0 auto;
  padding-left: 0;
  left: 0;
  right: 0;
}

::v-deep #treeList .dx-placeholder {
  padding-left: 20px;
}

::v-deep #treeList .dx-treelist-search-panel {
  margin: 0;
}

::v-deep #treeList .clicked-row > td {
  background: #b3e5fc;
}
</style>