<!--
  PACKAGE_NAME : src/pages/cc/cti/ibg-category
  FILE_NAME : top-btn-box.vue
  AUTHOR : hmlee
  DATE : 2024-07-09
  DESCRIPTION : 상단 버튼 컴포넌트
-->
<template>
  <!-- 상단 버튼 -->
  <div class="tree-box">
    <div class="top-btn inline-flex justify-between w-full">
      <DxButton
        :text="$_msgContents('CC.WORD.REGISTER_1ST_GROUP', { defaultValue: '1차 그룹 등록' })"
        type="button"
        class="btn_XS default filled add1"
        :height="30"
        @click="onAddRootGroup"
      />
    </div>
    <div class="top-toolbar-box py-2.5">
      <DxButton
        template="<span class='mdi mdi-folder-open'></span>"
        type="button"
        :hint="$_msgContents('COMPONENTS.EXPAND_LIST', {defaultValue: '목록 펼치기'})"
        class="btn_XS white outlined"
        :height="30"
        @click="onOpenTree"
      />
      <DxButton
        template="<span class='mdi mdi-folder'></span>"
        type="button"
        :hint="$_msgContents('COMPONENTS.COLLAPSE_LIST', {defaultValue: '목록 접기'})"
        class="btn_XS white outlined"
        :height="30"
        @click="onFoldTree"
      />
      <DxButton
        template="<span class='mdi mdi-chevron-double-up'></span>"
        :height="30"
        type="button"
        :hint="$_msgContents('COMPONENTS.MOVE_TO_TOP', {defaultValue: '맨 위로 이동'})"
        class="btn_XS white outlined"
        @click="onSetOrder(0)"
      />
      <DxButton
        template="<span class='mdi mdi-chevron-up'></span>"
        :height="30"
        type="button"
        :hint="$_msgContents('COMPONENTS.MOVE_UP', {defaultValue: '위로 이동'})"
        class="btn_XS white outlined"
        @click="onSetOrder(1)"
      />
      <DxButton
        template="<span class='mdi mdi-chevron-down'></span>"
        :height="30"
        type="button"
        :hint="$_msgContents('COMPONENTS.MOVE_DOWN', {defaultValue: '아래로 이동'})"
        class="btn_XS white outlined"
        @click="onSetOrder(2)"
      />
      <DxButton
        template="<span class='mdi mdi-chevron-double-down'></span>"
        :height="30"
        type="button"
        :hint="$_msgContents('COMPONENTS.MOVE_TO_BOTTOM', {defaultValue: '맨 아래로 이동'})"
        class="btn_XS white outlined"
        @click="onSetOrder(3)"
      />
      <DxButton
        :template="$_msgContents('COMPONENTS.SAVE_ORDER', {defaultValue: '순서 저장'})"
        :height="30"
        type="button"
        class="btn_XS white outlined"
        @click="onSaveSort"
      />
      <DxButton
        :template="$_msgContents('COMPONENTS.DELETE', {defaultValue: '삭제'})"
        :height="30"
        type="button"
        class="btn_XS white outlined"
        @click="onDeleteData"
      />
    </div>
  </div>

</template>

<script>

import {DxButton} from 'devextreme-vue/button';
import {isSuccess} from "@/plugins/common-lib";

export default {
  components: {
    DxButton,
  },
  props: {
    treeList: Array,
    selectedRowDatas: Array,
    clickedRowData: Object,
    clickedRowElement: HTMLTableRowElement,
  },
  watch: {},
  data() {
    return {
      config: {
        stylingMode: 'outlined',    //[outlined, filled, underlined]
        isViewFl: true,
      },
      sortList: [],
    }
  },
  computed: {
    /** @description: 1depth 최대 순서값 조회 */
    getMaxOrdBy1Depth() {
      return this.treeList.filter(d => d.depth === 1).length;
    }
  },
  methods: {
    /** @description: 1차 그룹 추가 */
    onAddRootGroup() {
      this.$parent.onOpenModal(
        'ModalIbgCategory',
        {
          title: this.$_msgContents('CC.WORD.REGISTER_1ST_GROUP', {defaultValue: '1차 그룹 등록'}),
          buttons: {
            save: {text: this.$_msgContents('COMPONENTS.SAVE', {defaultValue: '저장'})},
            cancel: {text: this.$_msgContents('COMPONENTS.CANCEL', {defaultValue: '취소'})},
          },
          width: '500',
          height: '250',
        },
        {
          depth: 1,
          parentId: -1,
          ibgCtgOrd: this.getMaxOrdBy1Depth,
        }
      )
    },
    /** @description: 트리 펼치기 */
    onOpenTree() {
      const rootIds = this.treeList.map(d => {
        return d.id;
      });
      this.$emit('openTree', rootIds);
    },
    /** @description: 트리 접기 */
    onFoldTree() {
      this.$emit('foldTree', []);
    },
    /** @description: 순서 설정 이벤트 */
    async onSetOrder(type) {
      const selectedRowData = this.clickedRowData;

      // 0 :first 1:up 2: down 3:last 3
      let item = this.treeList.find(d => d.id === selectedRowData.id),
        groupList,
        preItemCopy,
        nextItemCopy,
        preItemIndex,
        preItem,
        nextItemIndex,
        nextItem,
        realPreItemIndex,
        realNextItemIndex,
        lastItemIndex;
      const itemIndex = this.treeList.indexOf(item);

      switch (type) {
        case 0:
          preItemCopy = JSON.parse(JSON.stringify(this.treeList)).find(
            d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId,
          );

          if (preItemCopy) {
            let firstItemIndex = this.treeList.findIndex(d => d.id === preItemCopy.id);

            this.treeList.splice(itemIndex, 1);
            this.treeList.splice(firstItemIndex, 0, item);
          }
          break;
        case 1:
          groupList = JSON.parse(JSON.stringify(this.treeList)).filter(
            d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId,
          );
          preItemIndex = groupList.findIndex(d => d.id === selectedRowData.id) - 1;

          if (0 > preItemIndex) return;

          preItem = groupList[preItemIndex];

          realPreItemIndex = this.treeList.findIndex(d => d.id === preItem.id);
          this.treeList.splice(itemIndex, 1);
          this.treeList.splice(realPreItemIndex, 0, item);

          break;
        case 2:
          groupList = JSON.parse(JSON.stringify(this.treeList)).filter(
            d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId,
          );
          nextItemIndex = groupList.findIndex(d => d.id === selectedRowData.id) + 1;

          if (groupList.length - 1 < nextItemIndex) return;

          nextItem = groupList[nextItemIndex];

          realNextItemIndex = this.treeList.findIndex(d => d.id === nextItem.id);
          this.treeList.splice(itemIndex, 1);
          this.treeList.splice(realNextItemIndex, 0, item);

          break;
        case 3:
          nextItemCopy = JSON.parse(JSON.stringify(this.treeList))
            .reverse()
            .find(d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId);

          if (nextItemCopy) {
            lastItemIndex = this.treeList.findIndex(d => d.id === nextItemCopy.id);
            this.treeList.splice(itemIndex, 1);
            this.treeList.splice(lastItemIndex + 1, 0, item);
          }

          break;
        default:
          break;
      }

      //순서 처리 및 세팅
      this.$emit('setOrder', {list: this.treeList, targetData: selectedRowData});
    },

    /** @description: 순서 처리 및 세팅 */
    setSort(list, targetData) {
      //해당 depth의 순서 처리
      const sortList = list.filter(d => d.parentId === targetData.parentId).map((item, index) => {
        item.index = index + 1;
        return item
      });

      //this.sortList에 변경된 순서 담기
      sortList.forEach(item => {
        let index = this.sortList?.findIndex(el => el.id === item.id);
        if (index && index !== -1) { //이미 한번 순서를 변경했으면 기존꺼를 변경
          this.sortList[index] = item;
        } else { //순서를 변경한적이 없으면 추가
          this.sortList.push(item);
        }
      });
    },
    /** @description: 순서 저장 메서드 */
    onSaveSort() {
      //변경된 순서 데이터 설정
      this.sortList.forEach(item => {
        item.ibgCtgOrd = item.index;
      });

      this.$parent.updateData(this.sortList);
    },
    /** @description: 데이터 삭제 메서드 */
    async onDeleteData() {
      const selectedRowDatas = this.selectedRowDatas;
      if (selectedRowDatas.length === 0) {
        this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        return false;
      }

      const selectedIds = selectedRowDatas.map(d => d.id);
      const childrenIdArr = this.findChildrenById(selectedIds, this.treeList);
      const deletedIds = childrenIdArr.map(d => {
        return {id: d}
      });

      const msgContents = this.$_msgContents('CC.MESSAGE.CFM_DELETE_SELECTED_WITH_CHILD', {defaultValue: '선택한 데이터를 삭제하시겠습니까?<br/>하위 데이터도 함께 삭제됩니다.'});
      if (await this.$_Confirm(msgContents)) {

        const payload = {
          actionname: 'CC_IBG_CATEGORY_DELETE',
          data: deletedIds,
        }

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_DELETE', {defaultValue: '정상적으로 삭제되었습니다.'}));
          this.$emit('deleteDatas', res);
        }
      }
    },
    /** @description : 여러개의 id로부터 자신 포함 자식 배열을 가져오는 메서드 */
    findChildrenById(ids, arr) {
      const childrens = new Set(ids); // 여러개의 id를 포함

      function findChildren(parentId) {
        arr.forEach(item => {
          if (item.parentId === parentId) {
            childrens.add(item.id);
            findChildren(item.id); // 재귀 호출로 자식 항목의 자식 항목도 찾음
          }
        });
      }

      ids.forEach(id => findChildren(id));
      return Array.from(childrens);
    },
    /** @description : 라이프 사이클 created시 호출되는 메서드 */
    async createdData() {
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
  },
}
</script>

<style scoped>
.top-toolbar-box {
  margin: 0;
}
</style>